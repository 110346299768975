import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import "./layout.css"
import "./styles/home.css"

const HomeHeader = ({ siteTitle }) => (
  <>
  <header className="home-header">
    <div className="header__intro">
      <h1 className="dark-theme-text">Hi, I'm Josh Kirby</h1>
      <p className="portfolio-description">Welcome to my portfolio! Please take a look around, and contact me <a href="mailto:joshua.dean.kirby@gmail.com">here</a> if you like what you see.</p>
    </div>
    <p className="temp-note">More pages to explore coming soon!</p>
  </header>
  </>
)

HomeHeader.propTypes = {
  siteTitle: PropTypes.string,
}

HomeHeader.defaultProps = {
  siteTitle: ``,
}

export default HomeHeader
