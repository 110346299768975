// import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"

// import PortfolioIcon from "../images/joshkirby_portfolio_icon.png"
// import Resume from "../documents/Joshua Kirby Dev Resume v2.3.pdf"

import { FaGithub, FaLinkedin, FaEnvelope } from "react-icons/fa" // I may also want to add FaFileContract, FaFileAlt

const Footer = () => (
  <footer className="footer dark-theme-text">
    <div className="social-media">
      <a href="https://www.linkedin.com/in/joshua-d-kirby" title="LinkedIn profile" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
      <a href="https://github.com/Junosprite007" title="GitHub profile" target="_blank" rel="noopener noreferrer"><FaGithub /></a>
      <a href="mailto:joshua.dean.kirby@gmail.com" title="Email Josh"><FaEnvelope /></a>
      {/* <a href={ Resume } title="Resume" target="_blank" rel="noopener noreferrer"><FaFileAlt /></a> */}
    </div>
    <div className="footer__attributions">
      Built and maintained by Joshua Kirby.
      {/* {new Date().getFullYear()} */}
    </div>
  </footer>
)

export default Footer
